import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Reports = () => (
  <SvgIcon style={{ fill: 'none' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6429 0.499985V10.3571H23.5C23.5 4.91318 19.0868 0.499985 13.6429 0.499985Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3571 3.78569C4.91319 3.78569 0.5 8.19888 0.5 13.6428C0.5 19.0868 4.91319 23.5 10.3571 23.5C15.8011 23.5 20.2143 19.0868 20.2143 13.6428H10.3571V3.78569Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Reports;
